<template>
  <div @click="openDetail(ticket.code)" class="col-6">
    <div
      class="card card-style mx-0 mb-4"
      :style="{
        backgroundImage: `url(${ticketImage})`,
      }"
      data-card-height="170"
    >
      <div class="card-bottom p-3">
        <h2 class="color-white font-13 line-height-xs">{{ ticket.title }}</h2>
        <p class="color-white opacity-60 line-height-s font-12">
          {{ ticket.room }} - Rp.{{ ticket.price }}
        </p>
      </div>
      <div class="card-overlay bg-gradient opacity-30"></div>
      <div class="card-overlay bg-gradient"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: Object,
  },
  computed: {
    ticketImage() {
      return "https://sbticket.kreya.my.id" + this.ticket.image;
      /*return true;*/
    },
  },
  mounted() {
    console.log("ticket : ", this.ticket);
  },
  methods: {
    openDetail(slug) {
      this.$router.push({ name: "TicketDetail", params: { slug: slug } });
    },
  },
};
</script>
