<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Home'" />

    <div class="page-title-clear"></div>
    <div class="page-content">
      <div class="card notch-clear rounded-0 gradient-dark mb-n5">
        <div class="card-body pt-4">
          <h1 class="color-white font-30 float-start">Welcome</h1>

          <div clas="clearfix"></div>
        </div>

        <div class="card-body mx-0 px-0 mt-n3 mb-2">
          <div
            class="card card-style"
            style="background-image: url(/img/wonderfull.jpg)"
            data-card-height="300"
          >
            <div class="card-top px-3 py-3"></div>
            <div class="card-bottom px-3 py-3">
              <h1 class="color-white">Wonderfull</h1>
            </div>
            <div class="card-overlay bg-gradient opacity-30"></div>
            <div class="card-overlay bg-gradient"></div>
          </div>
        </div>
      </div>

      <div class="card card-style bg-transparent shadow-0 mb-2 rounded-sm">
        <div
          class="
            search-box search-dark
            shadow-sm
            border-0
            mt-4
            bg-theme
            rounded-sm
            bottom-0
          "
        >
          <i class="fa fa-search ms-n3"></i>
          <input
            v-model="searchData"
            type="text"
            class="border-0"
            placeholder="Searching for something? Try 'Wonderfull'"
            data-search
            @input="search"
          />
        </div>
      </div>
      <div class="content mb-0">
        <a href="#" data-menu="order-2">
          <div class="row mb-0">
            <!-- Ticket List -->
            <CardSmall
              v-for="ticket in ticketData"
              :ticket="ticket"
              :key="ticket.code"
            />
            <!-- End Ticket List -->
          </div>
        </a>
      </div>
    </div>

    <!-- Page content ends here-->

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuClose } from "@/appkit.js";

import CardSmall from "../components/CardSmall";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  components: { Header, Footer, PageTitle, CardSmall },
  data() {
    return {
      searchData: "",
    };
  },
  computed: {
    ticketData() {
      return this.$store.getters["ticket/getTicketList"];
    },
  },
  mounted() {
    init_template();
  },
  methods: {
    menuClose,
    search() {
      this.ticketData.filter((value) => {
        console.log("filter : ", value.title.toLowerCase() == this.searchData);
        value.title.toLowerCase() === this.searchData;
      });
    },
  },
};
</script>
